
const TWILIO_INITIAL_STATE = {
	messages: [],
	unreadMessages: 0,
	messagesActive: false,
};

const Twilio = (state = TWILIO_INITIAL_STATE, action) => {
	switch (action.type) {
		case "ADD_MESSAGE":
			return Object.assign({}, state, {
				...state,
				unreadMessages: !state.messagesActive ? state.unreadMessages + 1 : state.unreadMessages,
				messages: [action.item, ...state.messages],
			});
		case "ADD_UNREAD_MESSAGE":
			return Object.assign({}, state, {
				...state,
				unreadMessages: state.unreadMessages + 1,
			});
		case "CLEAR_MESSAGES":
			return Object.assign({}, state, {
				...state,
				messages: [],
			});
		case "CLEAR_UNREAD_MESSAGES":
			return Object.assign({}, state, {
				...state,
				unreadMessages: 0,
			});
		case "TOGGLE_MESSAGES":
			return Object.assign({}, state, {
				...state,
				messagesActive: action.item,
			});
		default:
			return state;
	}
};

export default Twilio;
