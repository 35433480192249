import React from 'react';
import { makeStyles, Container, Grid, Typography } from '@material-ui/core';
import DeleteOne from '../../images/Delete1.png';
import DeleteTwo from '../../images/Delete2.png';
import DeleteThree from '../../images/Delete3.png';
import { Colours } from '../../helpers/Colours';

const useStyles = makeStyles(() => ({
    root: {
        background: `linear-gradient(0.25turn, ${Colours.primaryDarker}, ${Colours.secondary})`,
        border: `1px solid #ddd`,
        padding: 0,
        "& p":{

        },
        "& img":{
            width: "100%"
        }
    },
    header: {
        fontSize: 34,
        fontWeight: 700,
        marginBottom: 28,
        marginTop: 50,
        color: Colours.primaryDark
    },

    lineItems: {
        fontSize: 28,
        fontWeight: 700,
        color: Colours.primaryDark
    },

    deletionText: {
        fontSize: 20,
        color: Colours.black,
    },
    image:{
        width: "100%",
        maxHeight: "80vh",
        objectFit: "contain"
    }
}));

export default function DeleteAccount() {
    const classes = useStyles();

    return <Container maxWidth="sm" classname={classes.root}>
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography align="center" variant="h1" color='primary' gutterBottom className={classes.header}>
                    How to delete account
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography align="center" variant="h3" color='primary' className={classes.lineItems}>Goto profile</Typography>
                <img src={DeleteOne} alt="Select Profile" className={classes.image}/>
            </Grid>
            <Grid item xs={12}>
            <Typography align="center" variant="h3" color='primary' className={classes.lineItems}>Select Delete Account</Typography>
                <img src={DeleteTwo} alt="Select Delete Account" className={classes.image}/>
            </Grid>
            <Grid item xs={12}>
            <Typography align="center" variant="h3" color='primary' className={classes.lineItems}>Confirm your account deletion. </Typography>
            <Typography align="center" variant="body" color='primary' className={classes.deletionText}>We may keep hold of your details to resolve all payments with you account. We will not allow you to delete your account if you have pending calls with experts. </Typography>
            
                <img src={DeleteThree} alt="Confirm your account deletion" className={classes.image}/>
            </Grid>
        </Grid>
    </Container>
}
