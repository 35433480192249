import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import UserController from '../../controllers/UserController';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { Container, Paper, Typography } from '@material-ui/core';
import { IndexedDB } from '../../helpers/IndexedDB';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';
import { Alert } from '../../components/Common/Alert';

export function EmailChangeConfirm(props) {
    const [loading, setLoading] = React.useState(true);
    const [success, setSuccess] = React.useState(false);
    const [newEmail, setNewEmail] = React.useState(false);
    const { PushHistory, Auth } = props;
    const { search } = props.location;

    // initialise
    React.useEffect(() => {
        async function init() {
            if (!Auth.isAuthenticated) {
                PushHistory('/');
                return;
            }
            const values = queryString.parse(search);
            const response = await UserController.confirmEmailChange(values.emailCode);
            if (response.hasError) {
                setSuccess(false);
            } else {
                await IndexedDB.remove('userName');
                await IndexedDB.add('userName', response.data.userName);
                setNewEmail(response.data.userName)
                setSuccess(true);
            }
            setLoading(false);
        }
        init();
    }, [props, search, PushHistory, Auth]);

    function buildEmailMessage() {
        if (loading) {
            return null;
        }
        return <Alert
            header={success ? newEmail : 'Something went wrong!'}
            text={success ? 'New email confirmed' : 'Failed to confirm new email'}
            severity={success ? 'success' : 'error'}
        />;
    }

    return <div style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100vh',
        background: 'linear-gradient(90deg, #C41AAA 0%, #6119C3 100%)'
    }}
    ><Container maxWidth="xs">
            <LoadingOverlay loading={loading} />
            <Paper style={{ padding: 24 }}>
                <Typography variant="h3" align="center" gutterBottom>
                    Email Confirmation
                </Typography>
                {buildEmailMessage()}
            </Paper>
        </Container>
    </div>;
}

const mapStateToProps = state => ({
    Auth: state.Authentication
});

const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmailChangeConfirm);

EmailChangeConfirm.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func
};