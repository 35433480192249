import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { makeStyles, Container, Typography, Box, Button, Link } from "@material-ui/core";
import { isNullOrUndefined } from "../../helpers/Utils";
import JobController from "../../controllers/JobController";
import { Alert } from "../../components/Common/Alert";
import { JobType } from "../../helpers/Constants";
import { LoadingOverlay } from "../../components/Common/LoadingOverlay";
import { Colours } from "../../helpers/Colours";
import TwilioController from "../../controllers/TwilioController";
import ExpertController from "../../controllers/ExpertController";
import { connect as twilioConnect, LocalDataTrack, createLocalTracks } from "twilio-video";
import Room from "../../components/Twilio/Room";
import { DateTime } from "luxon";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: 0,
		height: "100vh",
		overflow: "hidden",
		background: `linear-gradient(0.25turn, ${Colours.primaryDarker}, ${Colours.secondary})`,
		border: `1px solid #ddd`,
	},
	activeJobsWrapper: {
		height: 86,
		overflowX: "auto",
		display: "flex",
		alignItems: "center",
		paddingLeft: 12,
		[theme.breakpoints.down("xs")]: {},
	},
	activeJobItem: {
		transition: "background-color 0.2s",
		cursor: "pointer",
		marginRight: 12,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: 'white',
		backgroundColor: Colours.primary,
		borderRadius: "50%",
		fontSize: 16,
		width: 50,
		height: 50,

		"& img": {
			transition: "all 0.1s",
			width: 50,
			height: 50,
			objectFit: "cover",
			borderRadius: "50%",
		},

		"&.active": {
			boxShadow: "0px 0px 7px 5px  #fff",
		},
	},
	focusedJobWrapper: {
		backgroundColor: Colours.white,
		height: "calc(100% - 86px)",
		padding: 24,
		paddingTop: 36,
		borderTopLeftRadius: 16,
		borderTopRightRadius: 16,

		"& .MuiTypography-body1": {
			color: Colours.primaryDarker,
			fontSize: 20,
			// textTransform: "capitalize",
		},
		"& button": {
			textTransform: "capitalize",
		},
	},
}));

function Lobby() {
	const classes = useStyles();
	const [warningText, setWarningText] = React.useState(null);
	const [loading, setLoading] = React.useState(true);
	const [submitting, setSubmitting] = React.useState(false);

	const [profile, setProfile] = React.useState(null);
	const [serverTime, setServerTime] = React.useState(null);
	const [isExpert, setIsExpert] = React.useState(false);

	const [room, setRoom] = React.useState(null);
	const [selectedJob, setSelectedJob] = React.useState(null);
	const [activeJobs, setActiveJobs] = React.useState([]);

	async function fetchActiveJobs() {
		const response = await JobController.getActiveJobs();
		if (!response.hasError) {
			setActiveJobs(getVideoJobs(response.data));
		} else {
			setWarningText(response.data.message);
			setLoading(false);
		}
	}

	async function fetchExpertProfile() {
		const response = await ExpertController.getExpertProfile();
		if (!response.hasError) {
			setProfile(response.data);
		} else {
			setWarningText(response.data.message);
			setLoading(false);
		}
	}

	function getVideoJobs(jobs) {
		return jobs.filter((job) => job.type === JobType.Video);
	}

	React.useEffect(() => {
		async function init() {
			setWarningText(null);
			await fetchExpertProfile();
			await fetchActiveJobs();
			setLoading(false);
		}
		init();
	}, []);

	async function fetchTwilioVideoToken() {
		const response = isExpert ? await TwilioController.GenerateVideoTokenExpert(selectedJob.id) : await TwilioController.GenerateVideoTokenUser(selectedJob.id);

		if (!response.hasError) {
			return response.data.jwt;
		} else {
			setWarningText(response.data.message);
			setLoading(false);
		}
	}

	async function fetchRoomRequest() {
		const response = await TwilioController.requestRoomAndServerTime(selectedJob.id);

		if (!response.hasError) {
			return response.data;
		} else {
			setWarningText(response.data.message);
			setLoading(false);
		}
	}

	function handleLeaveRoom() {
		room.disconnect();
		setRoom(null);
	}

	async function handleJoinRoom() {
		setSubmitting(true);

		const token = await fetchTwilioVideoToken();
		const dataTrack = new LocalDataTrack({ id: "data" });

		const {roomName, serverTime} = await fetchRoomRequest();

		const roomOptions = {
			name: roomName,
			audio: true,
			video: true,
			networkQuality: true,
			dominantSpeaker: true,
		};
		const startTime = performance.now()

		const localTracks = await createLocalTracks({
			audio: true,
			video: true,
		});

		const room = await twilioConnect(token, { ...roomOptions, tracks: [...localTracks, dataTrack] });
		const endTime = performance.now()
		const responseTimeMs = endTime - startTime;
		const finalServerTime = DateTime.fromHTTP(serverTime).plus({milliseconds: responseTimeMs})
		setServerTime(finalServerTime);
		setRoom(room);
		setSubmitting(false);
	}

	function handleSelectJob(job) {
		setSelectedJob(job);
		setIsExpert(job.expertProfile.id === profile.id);
	}

	function buildActiveJobs() {
		return activeJobs.map((job) => {
			const iAmExpert = job.expertProfile.id === profile.id;
			return <Box key={job.id} className={`${classes.activeJobItem} ${selectedJob?.id === job.id && "active"}`} onClick={() => handleSelectJob(job)}>
				{iAmExpert ? job.userFirstName[0] + job.userLastName[0] : <img src={job.expertProfile.profilePic}></img>}
			</Box>;
	});
	}

	function buildSelectedJob() {
		return (
			<Box>
				<Typography variant="body1" gutterBottom>
					Join call with {selectedJob.expertProfile.userName}
				</Typography>
				<Typography variant="body2">Details:</Typography>
				<Typography variant="body2" gutterBottom>
					{selectedJob.requestInfo}
				</Typography>
				<Box height={12} />
				<Button variant="contained" disableElevation color="primary" onClick={handleJoinRoom} disabled={submitting}>
					Join Call
				</Button>
			</Box>
		);
	}

	function buildLobby() {
		return (
			<Box style={{ height: "100vh" }}>
				<Box style={{ padding: 8 }}>
					<Link href="/logout" style={{ color: 'white'}}><b>Logout</b></Link>
				</Box>
				<Box className={classes.activeJobsWrapper}>{buildActiveJobs()}</Box>
				<Box className={classes.focusedJobWrapper}>
					{!isNullOrUndefined(selectedJob) ? (
						buildSelectedJob()
					) : !activeJobs.length ? (
						<>
						<Typography align="center" variant="body2" gutterBottom>
								Only jobs that have reached their start time will appear here.
								<br/>
								<br/>
								If you don&apos;t see your job here yet, try refreshing the page when the start time has passed. 
								<br/>
								<br/>
								You currently have no active jobs
							</Typography>
						</>
					) : (
						<Typography align="center" gutterBottom>
							Select a Job
						</Typography>
					)}
				</Box>
			</Box>
		);
	}

	return (
		<Container maxWidth="md" className={classes.root}>
			{!isNullOrUndefined(room) ? <Room handleLeaveRoom={handleLeaveRoom} room={room} job={selectedJob} serverTime={serverTime} /> : buildLobby()}
			<Alert header="Failed to fetch jobs" text={warningText} />
			<LoadingOverlay loading={loading || submitting} />
		</Container>
	);
}

const mapStateToProps = (state) => ({
	Auth: state.Authentication,
});

const mapDispatchToProps = (dispatch) => ({
	PushHistory: (data) => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Lobby);

Lobby.propTypes = {
	Auth: PropTypes.object,
	PushHistory: PropTypes.func,
};
