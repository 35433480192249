import React from 'react';

export default function NotFound() {
    return <div style={{ minHeight: '100vh', backgroundImage: 'url("/images/icons/homepage.png")', backgroundPosition: 'center', backgroundSize: 'cover' }}>
        <div style={{ minHeight: '100vh', width: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '32px', }}>
            <img src="/images/icons/connectGO_Logo_White.png" alt="fantasyGO" style={{ maxWidth: '620px', width: '100%' }} />
            <h4 style={{ fontSize: '32px', color: 'rgb(255, 255, 255)', textAlign: 'center', lineHeight: '1.28571429em', margin: 'calc(2rem - 0.14285714em) 0 1rem', textTransform: 'uppercase', fontStyle: 'italic' }}>Page Not Found</h4>
        </div>
    </div>;
}
