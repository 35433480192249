export const AddMessage = (item) => ({
	type: "ADD_MESSAGE",
	item,
});

export const AddUnreadMessage = (item) => ({
	type: "ADD_UNREAD_MESSAGE",
	item,
});

export const ToggleMessages = (item) => ({
	type: "TOGGLE_MESSAGES",
	item,
});

export const ClearUnreadMessages = (items) => ({
	type: "CLEAR_UNREAD_MESSAGES",
	items,
});

export const ClearMessages = (items) => ({
	type: "CLEAR_MESSAGES",
	items,
});
