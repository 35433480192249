import React, { Component } from "react";
import PropTypes from "prop-types";
import UserController from "../controllers/UserController";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import { LogIn, LogOut } from "../stores/Actions/Authentication";
import { IndexedDB } from "../helpers/IndexedDB";
import { isNullOrUndefined } from "../helpers/Utils";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import BlockedRoute from "../components/Routes/BlockedRoute/BlockedRoute";

// Pages
import Login from "../domain/Auth/Login";
import Lobby from "../domain/Video/Lobby";
import Logout from "../domain/Auth/Logout";
import Privacy from "../domain/Privacy/Privacy";
import EmailConfirm from "../domain/User/EmailConfirm";
import EmailChange from "../domain/User/EmailChange";
import EmailChangeConfirm from "../domain/User/EmailChangeConfirm";
import PasswordForgot from "../domain/User/PasswordForgot";
import PasswordReset from "../domain/User/PasswordReset";
import NotFound from "../domain/Error/NotFound";
import { Layout } from "../domain/Layout/Layout";
import DeleteAccount from "../domain/Privacy/DeleteAccount";

class App extends Component {
	constructor(props) {
		super(props);
		this.initTokenStore(props);
	}

	initTokenStore = async (props) => {
		// initialise local data store
		const init = await IndexedDB.init();
		if (init.hasError) {
			console.warn(init.data);
			return;
		}
		// check if existing token is valid
		if (await UserController.hasTokenExpired()) {
			props.LogOut();
			return;
		}
		// check for existing token
		const userData = await UserController.fetchCachedUserData();
		if (userData.hasError) {
			console.warn(userData.data);
			props.LogOut();
			return;
		}
		// save cached data if any
		const { userName, role } = userData.data;
		if (!isNullOrUndefined(userName)) {
			props.LogIn({
				userName,
				role,
				isLoggingIn: false,
				isAuthenticated: true,
			});
		} else {
			props.LogOut();
		}
	};

	render() {
		if (this.props.Auth.isLoggingIn) {
			return <div />;
		}
		return (
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<Layout>
					<Switch>
						<BlockedRoute exact path="/" component={Login} redirectTo="/Lobby" shouldBeAuthenticated={false} isAuthenticated={this.props.Auth.isAuthenticated} />
						<Route exact path="/Logout" component={Logout} />
						<Route exact path="/Privacy" component={Privacy} />
						<Route exact path="/EmailConfirmation" component={EmailConfirm} />
						<Route exact path="/ChangeEmail" component={EmailChange} />
						<Route exact path="/ChangeEmailConfirmation" component={EmailChangeConfirm} />
						<Route exact path="/ForgotPassword" component={PasswordForgot} />
						<Route exact path="/ResetPassword" component={PasswordReset} />
						<Route exact path="/DeleteAccount" component={DeleteAccount} />
						<BlockedRoute exact path="/Lobby" component={Lobby} redirectTo="/" shouldBeAuthenticated={true} isAuthenticated={this.props.Auth.isAuthenticated} />
						<Route path="" component={NotFound} />
					</Switch>
				</Layout> 
			</MuiPickersUtilsProvider>
		);
	}
}

const mapStateToProps = (state) => ({
	Auth: state.Authentication,
});

const mapDispatchToProps = (dispatch) => ({
	LogIn: (data) => dispatch(LogIn(data)),
	LogOut: () => dispatch(LogOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

App.propTypes = {
	Auth: PropTypes.object,
	LogIn: PropTypes.func,
	LogOut: PropTypes.func,
};
