import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { LogOut } from '../../stores/Actions/Authentication';
import UserController from '../../controllers/UserController';

function Logout(props) {
    UserController.logout();
    props.LogOut();
    props.PushHistory('/');
    return null;
}

const mapStateToProps = state => ({
    Auth: state.Authentication
});

const mapDispatchToProps = dispatch => ({
    PushHistory: data => dispatch(push(data)),
    LogOut: () => dispatch(LogOut()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Logout)

Logout.propTypes = {
    Auth: PropTypes.object,
    PushHistory: PropTypes.func,
    LogOut: PropTypes.func,
};