import React from 'react';
import PropTypes from 'prop-types';
import MOTD from './MOTD.js';
import { CheckVersion } from './CheckVersion';
import CheckToken from './CheckToken';
import { CheckApiLive } from './CheckApiLive';

export function Layout(props) {
    return <>
        <MOTD />
        <CheckVersion />
        <CheckToken />
        <CheckApiLive />
        {props.children}
    </>;
}

Layout.propTypes = {
    children: PropTypes.object,
};