import React from "react";
import PropTypes from "prop-types";
import { push } from 'connected-react-router';
import { Route } from "react-router-dom";
import { connect } from "react-redux";

function BlockedRoute({ component: Component, redirectTo, shouldBeAuthenticated, isAuthenticated, PushHistory, ...rest }) {
	React.useEffect(() => {
		if (isAuthenticated !== shouldBeAuthenticated) {
			PushHistory(redirectTo);
		}
	}, [isAuthenticated, shouldBeAuthenticated, PushHistory])

	return (
		<Route
			{...rest}
			render={(props) => {
				if (isAuthenticated === shouldBeAuthenticated) {
					return <Component {...props} />;
				}
				return null;
			}}
		/>
	);
}

const mapStateToProps = (state) => ({
	auth: state.authentication,
});

const mapDispatchToProps = (dispatch) => ({
	PushHistory: (data) => dispatch(push(data)),
});

BlockedRoute.propTypes = {
	component: PropTypes.any,
	redirectTo: PropTypes.string,
	shouldBeAuthenticated: PropTypes.bool,
	location: PropTypes.object,
	isAuthenticated: PropTypes.bool.isRequired,
	PushHistory: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockedRoute);
