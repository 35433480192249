import React from "react";
import PropTypes from "prop-types";
import UserController from "../../controllers/UserController";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { makeStyles, Container, Typography, Box } from "@material-ui/core";
import { isNullOrUndefined } from "../../helpers/Utils";
import { LogIn } from "../../stores/Actions/Authentication";
import { Colours } from "../../helpers/Colours";
import { LoadingOverlay } from "../../components/Common/LoadingOverlay";
import { QRCode } from "react-qr-svg";
import { Alert } from "../../components/Common/Alert";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		height: "100vh",

		"& .MuiTypography-h2": {
			wordSpacing: "-2px",
			fontSize: 28,
			fontWeight: 700,
			marginBottom: 28,
			color: Colours.primaryDark,
		},
	},
	innerWrapper: {
		border: `1px solid #ddd`,
		borderRadius: 12,
		padding: "42px 36px",

		[theme.breakpoints.down("xs")]: {
			padding: "36px 24px",
			border: "none",
		},
	},
	contentCenter: {
		display: "flex",
		flexDirection:'column',
		alignItems: 'center'
	}
}));

function Login({ Login, PushHistory }) {
	const [oneTime, setOneTime] = React.useState(null);
	const [interval, setCurrentInterval] = React.useState(null);
	const [loading, setLoading] = React.useState(false);
	const [redirectUrl, setRedirectUrl] = React.useState(null);
	const [warningText, setWarningText] = React.useState(null);
	const classes = useStyles();

	React.useEffect(() => {
		async function Init() {
			const response = await UserController.getOneTimeLogin();
			if (response.hasError) {
				setWarningText(response.data);
				setLoading(false);
				return;
			}
			setOneTime(response.data);
			setLoading(false);
		}
		Init();
	},[]);

	React.useEffect(()=>{
		if(oneTime !== null && interval === null){
			setCurrentInterval(setInterval(()=>{
				async function checkLoginStatus() {
					const response = await UserController.checkOneTimeLogin(oneTime.id, oneTime.privateKey);
					if (!response.hasError) {
						const { userName, role } = response.data;
						login(userName, role);
					}
				}
				checkLoginStatus();
			}, 5000))
		}
	},[oneTime, interval, setCurrentInterval])

	// redirect
	React.useEffect(() => {
		if (isNullOrUndefined(redirectUrl)) {
			return;
		}
		PushHistory(redirectUrl);
	}, [redirectUrl, PushHistory]);


	function login(userName, role) {
		Login({
			userName,
			role,
			isLoggingIn: false,
			isAuthenticated: true,
		});
		setRedirectUrl("/Lobby");
	}


	function buildQRCode() {
		if (oneTime === null) {
			return null;
		}

		return <div className={classes.contentCenter}>
			<QRCode
				bgColor="#FFFFFF"
				fgColor="#000000"
				level="Q"
				style={{ width: 200 }}
				value={`connectgo:${oneTime.id}`}
			/>
			<Typography variant="h6" align="center" style={{ paddingBottom: 20, paddingTop: 20 }}>
				Scan the QR code with the mobile app to log in
			</Typography>
			<Typography variant="subtitle" align="center" style={{ paddingBottom: 20 }}>
				Go to the User Settings page in-app and tap &apos;Web App Login&apos; to start scanning.
				<br/>
				<br/>
				If that doesn&apos;t work enter the code:
			</Typography>
			<Typography variant="h5" align="center">
				<b>{oneTime.id}</b>
			</Typography>
			<br/>
			<Typography variant="caption" color="secondary" align="center">
				<b>If you receive a &apos;Login Expired&apos; warning please refresh.</b>
			</Typography>
		</div>
	}

	return (
		<Container maxWidth="xs" className={classes.root}>
			<LoadingOverlay loading={loading} />
			<Alert header="Failed to load" text={warningText}/>
			<Box className={classes.innerWrapper}>
				<Typography align="center" variant="h2" color="primary" gutterBottom>
					Log In
				</Typography>
				{buildQRCode()}
			</Box>
		</Container>
	);
}

const mapStateToProps = (state) => ({
	Auth: state.Authentication,
});

const mapDispatchToProps = (dispatch) => ({
	PushHistory: (data) => dispatch(push(data)),
	Login: (data) => dispatch(LogIn(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

Login.propTypes = {
	Auth: PropTypes.object,
	PushHistory: PropTypes.func,
	Login: PropTypes.func,
};
