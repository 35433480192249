import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Box, makeStyles } from "@material-ui/core";
import { Colours } from "../../helpers/Colours";
import { isNullOrUndefined } from "../../helpers/Utils";

const useStyles = makeStyles(() => ({
	root:{
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		height: "100vh",
		width: "100%",
		position: "absolute",
		top: 0,
		left: 0,
		"&.non-video": {
			width: 0,
			height: 0,
		},

	},
	localRoot: {
		backgroundColor: Colours.primary,
		display: (styleProps) => styleProps.screenSharing ? "none" : "block",

		"& video": {
			width: "25%",
			position: "absolute",
			top: 36,
			left: 36,
			zIndex: 9, 
		},
	},
	remoteRoot: {
		width: "100%",

		"& video": {
			objectFit: "contain",
			width: "100%",
			height: "100%",
			maxHeight: "100vh",
			maxWidth: "100%",
		},
	},
}));

function Track({ track, local, isSharingScreen }) {
	const styleProps = {
		screenSharing: isSharingScreen,
	}
	const classes = useStyles(styleProps);
	const ref = React.createRef();
	const [initialised, setInitialised] = React.useState(false);

	React.useEffect(() => {
		if (!isNullOrUndefined(track) && track.kind !== "data" && !initialised) {
			const child = track.attach();
			ref.current.classList.add(track.kind);
			ref.current.appendChild(child);
			setInitialised(true);
		}
	}, [track, ref]);

	return <Box className={`${classes.root} ${!isNullOrUndefined(track) && track.kind !== "video" ? "non-video" : ""}`}>
		{
			local ? <Box className={classes.localRoot} ref={ref}></Box> : <Box className={classes.remoteRoot} ref={ref}></Box>
		}
	</Box>
}

const mapStateToProps = (state) => ({
	Auth: state.Authentication,
});

const mapDispatchToProps = (dispatch) => ({
	PushHistory: (data) => dispatch(push(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Track);

Track.propTypes = {
	Auth: PropTypes.object,
	PushHistory: PropTypes.func,
	track: PropTypes.any,
	local: PropTypes.bool,
	isSharingScreen: PropTypes.bool,
};
