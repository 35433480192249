export const Colours = {
    primary: '#a879dc',
    primaryLight: '#ddccf2',
    primaryLightTranslucent: 'rgba(221, 204, 242, 0.8)',
    primaryDark: '#8341cd',
    primaryDarkTranslucent: 'rgba(131, 65, 205, 0.8)',
    primaryDarker: '#6119c3',
    secondary: '#c41aaa',
    white: '#FFF',
    black: '#000',
    black70: 'rgba(0,0,0,0.7)',
};
