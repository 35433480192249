import React from "react";
import PropTypes from "prop-types";
import Track from "./Track";
import { Box } from "@material-ui/core";
import { isNullOrUndefined } from "../../helpers/Utils";

function Participant({participant, local, isSharingScreen, hidden = false}) {

	return !isNullOrUndefined(participant) && !hidden ? (
		<Box id={participant.identity}>
			{Array.from(participant.tracks.values())
				.filter((el) => !isNullOrUndefined(el))
				.map((track, i) => {
					const tracks = Array.from(participant.tracks.values());

					const containsScreenCapture = !!tracks.find(track => {  
						return track.trackName === 'ScreenCapture'
					})

					if(containsScreenCapture && !isNullOrUndefined(track.track) && track.track.name !== "ScreenCapture"){
						return;
					}
					return <Track key={i} track={track.track} local={local} isSharingScreen={isSharingScreen} />
				})
			}
		</Box>
	) : (
		<></>
	);
}

export default Participant;

Participant.propTypes = {
	participant: PropTypes.any,
	local: PropTypes.bool,
	isSharingScreen: PropTypes.bool,
	hidden: PropTypes.bool,
};
