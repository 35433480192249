import Axios from "axios";
import UserController from "./UserController";
import { APIRoute, APIPath } from "../helpers/Constants";
import { generateResponse } from "../helpers/Utils";

const ExpertController = {
  getExpertProfile,
};

async function getExpertProfile() {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.EXPERT_PROFILE, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    })
}



export default ExpertController;