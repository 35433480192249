import React from 'react';
import { APIPath, APIRoute } from '../../helpers/Constants';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Card, CardHeader, CardContent, CardActions, Divider } from '@material-ui/core';
import Axios from 'axios';
import { Build } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    root: {
        position: "fixed",
        top: 10,
        zIndex: 100000,
        left: 10,
        width: 'calc(100% - 20px)',
        height: 'calc(100% - 20px)'
    },
}));

export function CheckApiLive() {
    const [apiDown, setAPIDown] = React.useState(false);
    const classes = useStyles();

    const checkApi = React.useCallback(async () => {
        return Axios.get(APIRoute + APIPath.PASSWORD_REQUIREMENTS).then(() => {
            setAPIDown(false);
        }).catch(reason => {
            if(reason.response){
                if(reason.response.status === 503 || reason.response.status === 403){
                    setAPIDown(true);
                    setTimeout(() => {
                        checkApi();
                    }, 5000);
                }
            }
            else if(reason.message ==='Network Error'){
                setAPIDown(true);
                setTimeout(() => {
                    checkApi();
                }, 5000);
            }
        });
        
    }, [setAPIDown]);

    // initialise
    React.useState(() => {
        checkApi();
    }, [checkApi]);

    return apiDown
        ? <Card elevation={6} className={classes.root}>
            <CardHeader title="Maintenance In progress" />
            <Build/>
            <Divider/>
            <CardContent>
                We are unable to connect to the the server. We will automatically try and reconnect every 5 seconds
            </CardContent>
            <CardActions>
                <Button fullWidth color="secondary" variant="contained" disableElevation onClick={() => checkApi()}>
                    Check now
                </Button>
            </CardActions>
        </Card>
        : null;
}