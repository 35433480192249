import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { makeStyles, Container, Typography, Box, IconButton, TextField, Badge } from "@material-ui/core";
import { Colours } from "../../helpers/Colours";
import { DateTime } from "luxon";
import { isNullOrUndefined, isNullOrWhitespace } from "../../helpers/Utils";

import Participant from "./Participant";

import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import MicIcon from "@material-ui/icons/Mic";
import MicOffIcon from "@material-ui/icons/MicOff";
import PhoneIcon from "@material-ui/icons/Phone";
import VideocamIcon from "@material-ui/icons/Videocam";
import VideocamOffIcon from "@material-ui/icons/VideocamOff";
import ScreenShareIcon from "@material-ui/icons/ScreenShare";
import SendIcon from "@material-ui/icons/Send";
import StopScreenShareIcon from "@material-ui/icons/StopScreenShare";
import { AddMessage, ClearMessages,AddUnreadMessage, ClearUnreadMessages, ToggleMessages } from "../../stores/Actions/TwilioMessages";
import { LocalVideoTrack } from "twilio-video";

const useStyles = makeStyles(() => ({
	root: {
		padding: 0,
		height: "100vh",
		position: "relative",
		backgroundColor: Colours.black,
	},
	screenShareContainer: {
		border: "4px solid red",
		position: "fixed",
		top: 0,
		left: 0,
		width: "100vw",
		height: "100vh",
	},
	messageWrapper: {
		position: "absolute",
		bottom: 122,
		width: "100%",
		opacity: 0,
		transition: "all 0.5s",
		padding: 12,
		paddingTop: 0,
		pointerEvents: "none",



		// background: `linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%)`,

		"& .gradient":{
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: 100, 
		},
		
		"& .inner-wrapper": {
			display: "flex",
			flexDirection: "column-reverse",
			height: "50vh",
			maxHeight: "50vh",
			overflowY: "auto",
			position: "relative",
		},
		"&.active": {
			pointerEvents: "auto",
			opacity: 1,
		},
	},
	message: {
		borderRadius: 5,
		width: "fit-content",
		padding: 8,
		marginBottom: 12,
		color: Colours.white,
		"&.local": {
			backgroundColor: Colours.primaryDarkTranslucent,
			right: 20,
			alignSelf: "flex-end",
		},
		"&.remote": {
			alignSelf: "flex-start",
			backgroundColor: Colours.primaryLightTranslucent,
			left: 20,
		},
	},
	textField: {
		borderRadius: 5, 
		backgroundColor: Colours.white,
		width: "calc(100% - 64px)",
	},
	sendMessage: {
		backgroundColor: Colours.white,
		borderRadius: 5,
		padding: 16,
	},
	optionsWrapper: {
		position: "absolute",
		bottom: 42,
		width: "100%",
		height: 80,
		backgroundColor: Colours.black70,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		borderTop: `1px solid #222`,

		"& .inner-wrapper": {
			display: "flex",
			justifyContent: "space-between",
			"& svg": {
				fill: Colours.white,
				maxWidth: 300,
			},
		},
	},
	badge:{
		// width: 12,
	},
	leaveRoomIcon: {
		transform: "rotate(135deg)",
		backgroundColor: Colours.secondary,
	},

	timeRemaining: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		position: "absolute",
		bottom: 0,
		width: "100%",
		height: 42,

		backgroundColor: Colours.primary,
		color: Colours.white,
	},
}));

function Room({ room, job, serverTime, handleLeaveRoom, AddMessage, TwilioStore, ClearUnreadMessages, ToggleMessages }) {
	const classes = useStyles();
	const [localParticipant, setLocalParticipant] = React.useState(null);
	const [remoteParticipant, setRemoteParticipant] = React.useState(null);

	const [message, setMessage] = React.useState("");

	const [isNotMuted, setIsMuted] = React.useState(false);
	const [isCamEnabled, setIsCamEnabled] = React.useState(false);
	const [isSharingScreen, setIsSharingScreen] = React.useState(false);
	const [screenTrack, setScreenTrack] = React.useState(null);

	const [remainingTime, setRemainingTime] = React.useState(null);
	const [dataTrack, setDataTrack] = React.useState(null);
	const [secondsPassed, setSecondsPassed] = React.useState(0);

	// listeners
	React.useEffect(() => {
		setLocalParticipant(room.localParticipant);

		if (!isNullOrUndefined(room.participants) && Array.from(room.participants.values()).length !== 0) {
			addParticipant(Array.from(room.participants.values())[0]);
		}
		localParticipantEventListeners();
		room.on("participantConnected", (participant) => addParticipant(participant));
		room.on("participantDisconnected", (participant) => removeParticipant(participant));
	}, []);

	React.useEffect(() => {
		setTimeout(function () {
			calculateRemainingTime();
		}, 1000);
	}, [secondsPassed]);

	React.useEffect(() => {
		const dataTrackPublished = {};

		dataTrackPublished.promise = new Promise((resolve, reject) => {
			dataTrackPublished.resolve = resolve;
			dataTrackPublished.reject = reject;
		});

		room.localParticipant.on("trackPublished", (publication) => {
			if (publication.track.id === "data") {
				dataTrackPublished.resolve();
			}
		});

		room.localParticipant.on("trackPublicationFailed", (error, track) => {
			if (track.id === "data") {
				dataTrackPublished.reject(error);
			}
		});
	}, []);

	const handleSetMessages = React.useCallback((text, isLocal) => {
		const msg = {
			message: text,
			timeStamp: DateTime.now(),
			local: isLocal,
		};
		AddMessage(msg);
	}, []);

	function sendMessage() {
		if(isNullOrWhitespace(message)) return;

		dataTrack.send(message);
		handleSetMessages(message, true);
		setMessage("");
	}

	function localParticipantEventListeners() {
		room.localParticipant.on("trackPublished", (track) => {
			if (track.kind === "data") {
				setDataTrack(track.track);
				track.on("message", (text) => {
					handleSetMessages(text, true);
					setMessage("");
				});
			}
		});
	}

	function addParticipant(participant) {
		setRemoteParticipant(null);
		console.log(`${participant.identity} has joined the room.`);
		participant.on("trackSubscribed", (track) => {
			setRemoteParticipant(participant);
			if (track.kind === "data") {
				track.on("message", (text) => {
					handleSetMessages(text, false);
				});
			}
		});
	}

	function removeParticipant(participant) {
		console.log(`${participant.identity} has left the room`);
		setRemoteParticipant(null);
	}

	function calculateRemainingTime() {
		const endTime = DateTime.fromISO(job.endTime,{zone:'utc'});
		const now = serverTime.plus({seconds: secondsPassed});
		const diff = now.diff(endTime, ["hours", "minutes", "seconds"]);
		setSecondsPassed(secondsPassed + 1);
		setRemainingTime(diff.toObject());
	}
	
	function handleToggleMessagePopup() {
		ClearUnreadMessages();
		ToggleMessages(!TwilioStore.messagesActive);
	}

	function handleToggleMute() {
		if (!isNotMuted) {
			localParticipant.audioTracks.forEach((publication) => {
				publication.track.disable();
			});
			setIsMuted(true);
		} else {
			localParticipant.audioTracks.forEach((publication) => {
				publication.track.enable();
			});
			setIsMuted(false);
		}
	}

	function handleToggleCamera() {
		if (!isCamEnabled) {
			localParticipant.videoTracks.forEach((publication) => {
				publication.track.disable();
			});
			setIsCamEnabled(true);
		} else {
			localParticipant.videoTracks.forEach((publication) => {
				publication.track.enable();
			});
			setIsCamEnabled(false);
		}
	}

	function handleToggleShareScreen() {
		if (isNullOrUndefined(screenTrack)) {
			navigator.mediaDevices
				.getDisplayMedia()
				.then((stream) => {
					const newStream = LocalVideoTrack(stream.getTracks()[0]);
					setScreenTrack(newStream);
					setIsSharingScreen(true);
					localParticipant.publishTrack(newStream);
				})
				.catch(() => {
					alert("Could not share the screen.");
				});
		} else {
			room.localParticipant.unpublishTrack(screenTrack);
			setIsSharingScreen(false);
			setScreenTrack(null);
		}
	}

	function isRemoteParticipantScreenSharing() {
		try {
			const tracks = Array.from(remoteParticipant.tracks.values());
			return tracks.some(track => track.trackName === 'ScreenCapture');
		} catch {
			return false;
		}
	}

	function buildMessages() {
		return (
			<Box className={`${classes.messageWrapper} ${TwilioStore.messagesActive ? "active" : ""}`}>
				<Box className="gradient"/>
				<Box className="inner-wrapper">
					{TwilioStore.messages.map((message, i) => {
						const time = DateTime.fromISO(message.timeStamp).toLocal().toFormat("H:mm a");
						const isLocal = message.local;
						return (
							<Box key={i} className={`${classes.message} ${isLocal ? "local" : "remote"}`}>
								<Typography variant="body1" gutterBottom>
									{message.message}
								</Typography>
								<Typography variant="caption">{time}</Typography>
							</Box>
						);
					})}
				</Box>
				<Box
					style={{
						display: "flex",
						justifyContent: "space-between",
						margin: "0 auto",
					}}
				>
					<TextField className={classes.textField} variant="outlined" fullWidth value={message} onChange={(event) => setMessage(event.target.value)} placeholder="Type your message..." />
					<IconButton className={classes.sendMessage} onClick={sendMessage}>
						<SendIcon color="primary" />
					</IconButton>
				</Box>
			</Box>
		);
	}

	function buildOptions() {
		return (
			<Box className={classes.optionsWrapper}>
				<Box className="inner-wrapper">
					<IconButton onClick={handleToggleMessagePopup}>
						<Badge 
							className={classes.badge} 
							badgeContent={TwilioStore.unreadMessages} 
							color="secondary"
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
						>
							{TwilioStore.messagesActive ? <ChatBubbleIcon /> : <ChatBubbleOutlineIcon />}
						</Badge>
					</IconButton>
					<IconButton onClick={handleToggleMute}>{isNotMuted ? <MicOffIcon /> : <MicIcon />}</IconButton>
					<IconButton className={classes.leaveRoomIcon} onClick={handleLeaveRoom}>
						<PhoneIcon />
					</IconButton>
					<IconButton onClick={handleToggleCamera}>{isCamEnabled ? <VideocamOffIcon /> : <VideocamIcon />}</IconButton>
					<IconButton onClick={handleToggleShareScreen}>{isSharingScreen ? <StopScreenShareIcon /> : <ScreenShareIcon />}</IconButton>
				</Box>
			</Box>
		);
	}

	function buildTimeRemaining() {
		return (
			<Box className={classes.timeRemaining}>
				{!isNullOrUndefined(remainingTime) ? (
					<>
						<Typography variant="body1" style={{ marginRight: 4 }}>
							0{Math.floor(remainingTime.hours) * -1} hr
						</Typography>
						<Typography variant="body1" style={{ marginRight: 4 }}>
							{Math.floor(remainingTime.minutes) * -1} min
						</Typography>
						<Typography variant="body1">{Math.floor(remainingTime.seconds) * -1} sec remaining</Typography>
					</>
				) : (
					<Typography variant="body1">00 hr 00 min 00 sec remaining</Typography>
				)}
			</Box>
		);
	}

	return (
		<Container maxWidth="md" className={classes.root}>
			{isSharingScreen && <div className={classes.screenShareContainer} />}
			<div className="room">
				<div className="participants">
					<Participant local={true} participant={localParticipant} isSharingScreen={isSharingScreen} hidden={isRemoteParticipantScreenSharing()} />
					{!isNullOrUndefined(remoteParticipant) && <Participant local={false} participant={remoteParticipant} />}
				</div>
				{buildMessages()}
				{buildOptions()}
				{buildTimeRemaining()}
			</div>
		</Container>
	);
}

const mapStateToProps = (state) => ({
	Auth: state.Authentication,
	TwilioStore: state.TwilioMessages,
});

const mapDispatchToProps = (dispatch) => ({
	PushHistory: (data) => dispatch(push(data)),
	AddMessage: (message) => dispatch(AddMessage(message)),
	AddUnreadMessage: () => dispatch(AddUnreadMessage()),
	ClearUnreadMessages: () => dispatch(ClearUnreadMessages()),
	ClearMessages: () => dispatch(ClearMessages()),
	ToggleMessages: (active) => dispatch(ToggleMessages(active)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Room);

Room.propTypes = {
	Auth: PropTypes.object,
	PushHistory: PropTypes.func,
	AddMessage: PropTypes.func,
	AddUnreadMessage: PropTypes.func,
	ClearUnreadMessages: PropTypes.func,
	handleLeaveRoom: PropTypes.func,
	ToggleMessages: PropTypes.func,
	TwilioStore: PropTypes.object,
	serverTime: PropTypes.any,
	room: PropTypes.any,
	job: PropTypes.any,
};
