import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import UserController from '../../controllers/UserController';
import { Alert } from '../../components/Common/Alert';
import { Container, Paper, Typography } from '@material-ui/core';
import { LoadingOverlay } from '../../components/Common/LoadingOverlay';

export default function EmailConfirm(props) {
    const [loading, setLoading] = React.useState(true);
    const [success, setSuccess] = React.useState(false);
    const { search } = props.location;

    // initialise
    React.useEffect(() => {
        async function init() {
            const values = queryString.parse(search);
            const { userId, emailCode } = values;
            const response = await UserController.checkForEmailConfirmation(userId, emailCode);
            setSuccess(!response.hasError);
            setLoading(false);
        }
        init();
    }, [search])

    function buildEmailMessage() {
        if (loading) {
            return null;
        }
        return <Alert
            header={success ? 'Success' : 'Something went wrong!'}
            text={success ? 'Email confirmed' : 'Failed to confirm email'}
            severity={success ? 'success' : 'error'}
        />;
    }

    return <div style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '100vh',
        background: 'linear-gradient(90deg, #C41AAA 0%, #6119C3 100%)'
    }}
    >
        <Container maxWidth="xs">
            <LoadingOverlay loading={loading} />
            <Paper style={{ padding: 24 }}>
                <Typography variant="h3" align="center" gutterBottom>
                    Email Confirmation
                </Typography>
                {buildEmailMessage()}
            </Paper>
        </Container>
    </div>;
}

EmailConfirm.propTypes = {
    location: PropTypes.object
};