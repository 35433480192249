import Axios from "axios";
import UserController from "./UserController";
import { APIRoute, APIPath } from "../helpers/Constants";
import { generateResponse } from "../helpers/Utils";

const TwilioController = {
    requestRoomAndServerTime,   
  GenerateVideoTokenUser,
  GenerateVideoTokenExpert,
  GenerateConversationTokenUser,
  GenerateConversationTokenExpert,
};

async function requestRoomAndServerTime(jobId) {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.REQUEST_ROOM + jobId, userHeaders).then(response => {
        const serverTime = response.headers['date'];
        return generateResponse(false, {roomName: response.data, serverTime});

    }).catch(reason => {
        return generateResponse(true, reason);
    })
}

async function GenerateVideoTokenUser(jobId) {
  const userHeaders = await UserController.getUserHeaders();
  return Axios.get(APIRoute + APIPath.GENERATE_VIDEO_TOKEN_USER + jobId, userHeaders).then(response => {
      return generateResponse(false, response.data);
  }).catch(reason => {
      return generateResponse(true, reason);
  })
}

async function GenerateVideoTokenExpert(jobId) {
  const userHeaders = await UserController.getUserHeaders();
  return Axios.get(APIRoute + APIPath.GENERATE_VIDEO_TOKEN_EXPERT + jobId, userHeaders).then(response => {
      return generateResponse(false, response.data);
  }).catch(reason => {
      return generateResponse(true, reason);
  })
}

async function GenerateConversationTokenUser() {
  const userHeaders = await UserController.getUserHeaders();
  return Axios.get(APIRoute + APIPath.GENERATE_CONVERSATION_TOKEN_USER, userHeaders).then(response => {
      return generateResponse(false, response.data);
  }).catch(reason => {
      return generateResponse(true, reason);
  })
}

async function GenerateConversationTokenExpert() {
  const userHeaders = await UserController.getUserHeaders();
  return Axios.get(APIRoute + APIPath.GENERATE_CONVERSATION_TOKEN_EXPERT, userHeaders).then(response => {
      return generateResponse(false, response.data);
  }).catch(reason => {
      return generateResponse(true, reason);
  })
}

export default TwilioController;