import Axios from "axios";
import UserController from "./UserController";
import { APIRoute, APIPath } from "../helpers/Constants";
import { generateResponse } from "../helpers/Utils";

const JobController = {
    getActiveJobs,
    getUpcomingJobs,
    getRequestedJobs,
    getPreviousJobs,
};

async function getActiveJobs() {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.ACTIVE_JOBS, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    })
}

async function getUpcomingJobs() {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.UPCOMING_JOBS, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    })
}

async function getRequestedJobs() {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.REQUESTED_JOBS, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    })
}

async function getPreviousJobs() {
    const userHeaders = await UserController.getUserHeaders();
    return Axios.get(APIRoute + APIPath.PREVIOUS_JOBS, userHeaders).then(response => {
        return generateResponse(false, response.data);
    }).catch(reason => {
        return generateResponse(true, reason);
    })
}


export default JobController;