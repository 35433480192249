export const versionName = "WebApp";

export const versionNum = 13;

export const APIDomain = "https://fantasygo.azurewebsites.net";

export const APIRoute = APIDomain + "/api/";

export const APIPath = {
    // Users
    PASSWORD_REQUIREMENTS: 'User/PasswordRequirements',
    LOGIN: 'User/LogIn',
    LOGIN_PASSWORD_RESET: 'User/LogInWithPasswordReset',
    LOGIN_GOOGLE: 'User/LoginWithGoogle',
    LOGIN_APPLE: 'User/LoginWithApple',
    LOGOUT: 'User/LogOut',
    REGISTER: 'User/Register',
    REGISTER_GOOGLE:'User/RegisterWithGoogle',
    REGISTER_APPLE:'User/RegisterWithApple',
    REGISTER_MAGIC_LINK:'User/RegisterMagicLink',
    EMAIL_CONFIRMATION: 'User/EmailConfirmation',
    FORGOT_PASSWORD: 'User/ForgotPassword',
    RESET_PASSWORD: 'User/ResetPassword',
    CHANGE_PASSWORD: 'User/ChangePassword',
    GET_USER_INFO: 'User/GetUsersInfo',
    REQUEST_EMAIL_CHANGE: 'User/RequestChangeEmail',
    CONFIRM_EMAIL_CHANGE: 'User/ConfirmChangeEmail',
    REQUEST_EMAIL_CONFIRMATION: 'User/RequestEmailConfirmation',
    FINISH_EXTERNAL_LOGIN: 'User/CheckLocalUser',
    FINISH_EXTERNAL_SIGNUP: 'User/CreateLocalUser',
    TWO_FACTOR_GET_KEY: 'User/GetAuthenticatorCode',
    TWO_FACTOR_ADD: 'User/AddAuthentication',
    TWO_FACTOR_REMOVE: 'User/RemoveAuthentication',
    ADD_DEVICE: "User/AddDevice",
    UPDATE_USER_ALTERNATE_LOGINS: "User/UpdateUsersAlternateLogins",
    VERIFY_TWO_FACTOR: "User/VerifyTwoFactor",
    GET_MOTD: 'User/GetMOTD',
    SEEN_MOTD: 'User/SeenMOTD',
    REFRESH_TOKEN: 'User/RefreshToken',
    GET_ONE_TIME_LOGIN: 'User/GetOneTimeLogin',
    CHECK_ONE_TIME_LOGIN: 'User/CheckOneTimeLogin',
    
    // Notifications
    GET_UNREAD_NOTIF_COUNT: 'Notifications/GetUnreadCount',
    GET_TOP_NOTIFS: 'Notifications/GetTop',
    GET_ALL_NOTIFS: 'Notifications/GetAll',
    MARK_NOTIF_AS_READ: 'Notifications/MarkAsRead/',
    MARK_ALL_NOTIFS_AS_READ: 'Notifications/MarkAllAsRead',
    
    // Expert
    EXPERT_PROFILE: 'Expert/MyProfile',

    // File
    FILE_UPlOAD: 'File/Upload',
    FILE_GET_USERS: 'File/GetUsersFiles',
    
    // Job
    ACTIVE_JOBS: 'Job/MyActiveJobs',
    UPCOMING_JOBS: 'Job/UpcomingJobs',
    REQUESTED_JOBS: 'Job/RequestedJobs',
    PREVIOUS_JOBS: 'Job/PreviusJobs',

    // Twilio
    REQUEST_ROOM: 'Twilio/RequestRoom/',
    GENERATE_VIDEO_TOKEN_USER: 'Twilio/GenerateVideoTokenUser/',
    GENERATE_VIDEO_TOKEN_EXPERT: 'Twilio/GenerateVideoTokenExpert/',
    GENERATE_CONVERSATION_TOKEN_USER: 'Twilio/GenerateConversationTokenUser/',
    GENERATE_CONVERSATION_TOKEN_EXPERT: 'Twilio/GenerateConversationTokenExpert/',

    // Version
    GET_VERSION: 'Version'
}

export const SIGNALRPath ={
    Notifications : APIDomain + "/webhooks/notification"
}

export const Role = {
    Admin: 'Admin',
}

export const JobType = {
    Message: 1,
    Video: 2,
}