import './app/Polyfill'
import 'typeface-roboto';
import 'pwacompat';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import registerServiceWorker from './app/ServiceWorker';
import configureStore, { history } from './stores/Reducers/History';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Colours } from './helpers/Colours';
import App from './app/App';

const store = configureStore();
const rootElement = document.getElementById('root');
const theme = createMuiTheme({
    typography: {
        "fontFamily": "\"Montserrat\", \"Helvetica\", \"Arial\", sans-serif",
        "fontSize": 14,
        "fontWeightLight": 300,
        "fontWeightRegular": 400,
        "fontWeightMedium": 500,
        
    },
    palette: {
        primary: {
            main: Colours.primary,
        },
        secondary: {
            main: Colours.secondary,
            contrastText: Colours.white,
        },
    },
    status: {
        danger: 'red'
    },
    
});

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter>
        </Provider>
    </MuiThemeProvider>,
    rootElement
);

registerServiceWorker();